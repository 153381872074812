import React, { useCallback } from 'react'
import { Field, useFieldState } from 'formular'
import cx from 'classnames'
import { useUniqueId } from 'hooks'

import { CheckboxIcon, type CheckboxIconProps, Text, type TextStyle } from 'components/dataDisplay'

import InputError from '../InputError/InputError'


export type CheckboxProps = {
  className?: string
  field: Field<any>
  style?: CheckboxIconProps['style']
  label?: Intl.Message | string
  labelStyle?: TextStyle
  errorClassName?: string
  errorText?: Intl.Message | string
  customError?: React.ReactNode
  value?: any
  disabled?: boolean
  fullWidth?: boolean
  tabIndex?: number
  onClick?: () => void
  'aria-label'?: string
  'data-testid'?: string
}

const Checkbox: React.CFC<CheckboxProps> = (props) => {
  const {
    children, className, field, label, labelStyle = 'p2', style, value,
    disabled, fullWidth, errorClassName = 'mt-4', errorText, customError, onClick,
    tabIndex = 0, 'aria-label': ariaLabel, 'data-testid': dataTestId,
  } = props

  const state = useFieldState(field)

  const isCheckboxGroup = Array.isArray(state.value)
  const isChecked = isCheckboxGroup ? state.value.includes(value) : state.value

  const checkboxId = useUniqueId('checkbox-')
  const checkboxLabelId = `${checkboxId}-label`

  const rootClassName = cx('inline-flex cursor-pointer select-none items-start text-left align-top', className, {
    'cursor-not-allowed': disabled,
    'w-full': fullWidth,
  })

  const toggle = useCallback(() => {
    // checkbox group
    if (Array.isArray(state.value)) {
      if (state.value.includes(value)) {
        field.set(state.value.filter((v) => v !== value))
      }
      else {
        field.set([ ...state.value, value ])
      }
    }
    // single checkbox
    else {
      field.set(!state.value)
    }

    if (typeof onClick === 'function') {
      onClick()
    }
  }, [ field, state.value, value, onClick ])

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    toggle()
  }, [ toggle ])

  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLElement>) => {
    if (event.code === 'Space') {
      event.preventDefault()
      toggle()
    }
  }, [ toggle ])

  const handleLabelClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLElement
    if (target instanceof HTMLAnchorElement || target instanceof HTMLButtonElement) {
      return
    }

    event.preventDefault()
    toggle()
  }, [ toggle ])

  const error = customError ? (
    customError
  ) : (
    <InputError
      className={errorClassName}
      message={errorText || state.error}
      data-testid={`${dataTestId}Error`}
    />
  )

  const withLabel = Boolean(label || children)

  return (
    <>
      <div className={rootClassName}>
        <span
          id={checkboxId}
          className="flex items-center justify-center"
          role="checkbox"
          aria-label={ariaLabel}
          aria-labelledby={withLabel ? checkboxLabelId : null}
          aria-checked={isChecked}
          aria-disabled={disabled}
          data-testid={dataTestId}
          tabIndex={tabIndex}
          onClick={disabled ? undefined : handleClick}
          onKeyDown={disabled ? undefined : handleKeyDown}
        >
          <CheckboxIcon
            style={style}
            active={isChecked}
            disabled={disabled}
            error={Boolean(state.error)}
          />
        </span>
        {
          withLabel && (
            <label
              id={checkboxLabelId}
              className={disabled ? 'cursor-not-allowed' : 'cursor-pointer'}
              onClick={disabled ? undefined : handleLabelClick}
            >
              {
                Boolean(label) && (
                  <Text
                    className="ml-16"
                    message={label}
                    style={labelStyle}
                    color={disabled ? 'gray-80' : 'black'}
                    tag="div"
                    html
                  />
                )
              }
              {children}
            </label>
          )
        }
      </div>
      {
        Boolean(state.error) && error
      }
    </>
  )
}


export default Checkbox
