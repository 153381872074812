import React, { useEffect, useMemo, useRef } from 'react'
import cx from 'classnames'
import { useIntl } from 'intl'
import { Field, useFieldState } from 'formular'

import { getGlobalHtmlAttrs, type GlobalHTMLAttrs } from 'helpers/getters'
import { Form } from 'components/inputs'

import s from './SearchInput.module.css'


export const sizes = [ 30, 34 ] as const

export type SearchInputSize = typeof sizes[number]

export type SearchInputProps = GlobalHTMLAttrs<'HTMLInputElement'> & {
  size: SearchInputSize
  field: Field<string>
  placeholder: Intl.Message | string
  className?: string
}

const SearchInput: React.FunctionComponent<SearchInputProps> = (props) => {
  const { field, size, placeholder, className, ...rest } = props
  const { value } = useFieldState<string>(field)

  const intl = useIntl()
  const placeholderString = useMemo(() => intl.formatMessage(placeholder), [ intl, placeholder ])

  const inputRef = useRef<HTMLInputElement>(null)
  const handleChange = (event) => field.set(event.target.value)
  const htmlAttrs = getGlobalHtmlAttrs<GlobalHTMLAttrs<'HTMLInputElement'>>(rest)

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  return (
    <Form className={className} action="." data-cnstrc-search-form>
      <input
        {...htmlAttrs}
        className={cx(s.input, s[`size-${size}`], 'w-full text-black')}
        ref={inputRef}
        type="search"
        value={value}
        onChange={handleChange}
        placeholder={placeholderString}
        aria-label={placeholderString}
        data-testid="searchInput"
        data-cnstrc-search-input
        autoFocus
      />
    </Form>
  )
}

SearchInput.displayName = 'SearchInput'


export default SearchInput
